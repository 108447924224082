<template lang="pug">
div.body
  vue-headful(title="Ownmesh login")
  div.login
    h2.form-signin-heading
      img.logo(src="../assets/ownmesh-logo.svg")
    div.error {{ error_message }}
    form.form-signin(v-on:submit.prevent="login(username,password)")
      input.form-control(type="text"
                         name="username"
                         v-model="username"
                         placeholder="Username"
                         maxlength="254"
                         required="")
      div
        input.form-control(type="password"
                           name="password"
                           v-model="password"
                           v-on:keyup.enter="login(username,password)"
                           placeholder="Password"
                           required="")
        button.row.btn.btn-block(size="lg"  type="submit" variant="success"
                                 :class="login_button_class")
          icon(v-show="!connecting" name="sign-in-alt", scale="1.3")
          clip-loader.white(:loading="connecting" size="30px")
</template>

<script>
import CONF from '@/conf'
import { mapGetters } from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import 'vue-awesome/icons/sign-in-alt'
import Icon from 'vue-awesome/components/Icon'

export default {
  name: 'Login',
  components: {
    Icon,
    ClipLoader
  },
  data () {
    return {
      username: '',
      password: '',
      connecting: false,
      error_message: '',
      valid_input: false,
      login_button_class: 'disabled'
    }
  },

  computed: mapGetters({
    socket_state: 'get_socket_state'
  }),

  watch: {
    username: function () { this.on_user_input() },
    password: function () { this.on_user_input() },
    valid_input: function () { this.login_button_class = this.valid_input ? '' : 'disabled' }
  },

  mounted () {
  },

  methods: {
    on_user_input: function () {
      this.error_message = ''
      this.valid_input = (this.username.length > 4 && this.password.length > 4)
    },

    login: function (username, password) {
      this.connecting = true
      let data = {'username': username, 'password': password}
      let request = this.$http.post(CONF.LOGIN_URL, data, CONF.REQUEST_OPTIONS)
      request.then(
        response => {
          // if (response.body.result !== 'Success') {
          //   request.failed(response)
          //   this.connecting = false
          //   return
          // }
          // this.error_message = ''
          // let session_key = response.body.text
          // if (session_key) {
          this.$cookie.set(CONF.SESSION_COOKIE, response.body.text)
          this.$cookie.set('username', username)
          // }
          this.$store.commit('set_username', username)
          this.$router.push(this.$store.state.login_next)
        },
        response => {
          this.error_message = response.body.text
          console.error(`Login request: ${response.status} ${response.body.text}`)
          this.connecting = false
        }
      )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';

.body {
  background-color: $login_bgcolor;
  height: 100%;
  position: fixed;
  width: 100%;
}

.login {
  margin: 10% auto;
  padding: 25px;
  width: 300px;
}

.logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  opacity: .5;
  width: 240px;
}

.error {
  color: darken($red, 10);
  font-size: 1.5rem;
  height: 25px;
  text-align: center;
  text-shadow: 1px 1px $black_shadow_light;
}

button {
  background-color: $black_shadow_light;
  border-color: $form_bordercolor;
  border-left: 0;
  border-radius: 0 0 3px;
  border-top: 0;
  color: $white;
  display: inline-block;
  height: 55px;
  margin: 0;
  padding: 5px 7px 2px;
  vertical-align: top;
  width: 50px;
  &.disabled {
    background-color: lighten($login_bgcolor, 45);
    border-color: $form_bordercolor;
    color: $black_shadow;
  }
  &:hover {
    &:not(.disabled) {
      background-color: lighten($black_shadow_light, 10);
    }
  }
}

.form-control {
  border-color: $form_bordercolor;
}

input {
  background-color: lighten($login_bgcolor, 30);
  color: lighten($black, 20);
  font-size: 15px;
  height: 55px;
  &:focus {
    background-color: lighten($login_bgcolor, 40);
    border: 1px solid $orange;
  }
  &[name='username'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  &[name='password'] {
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: inline-block;
    margin-bottom: 5px;
    width: 200px;
    &:not(:focus) {
      border-top: 0;
    }
  }
}
</style>
